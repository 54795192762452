<template>
  <div>
    <v-card>
      <v-card-title>Quiz List</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="quiz in quizzesData"
            :key="quiz.id"
            @click="showQuestions(quiz.id)"
          >
            <v-list-item-title>{{ quiz.title }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="primary" @click.stop="editQuiz(quiz.id)"
                >mdi-pencil</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-dialog v-if="selectedQuiz" v-model="showPopup" max-width="700px">
      <v-card>
        <v-card-title>{{ selectedQuiz.title }}</v-card-title>
        <v-card-title>Time: {{ timerMinutes }}:{{ timerSeconds }}</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                v-for="(question, index) in selectedQuiz.questions"
                :key="question.id"
              >
                <v-card v-if="index === activeQuestionIndex">
                  <v-card-title>{{ question.question_text }}</v-card-title>
                  <v-card-text>
                    <template
                      v-if="question.question_type === 'Checkbox for Single'"
                    >
                      <v-radio-group v-model="selectedOptions[question.id]">
                        <v-radio
                          v-for="option in question.options"
                          :key="option.id"
                          :label="option.option_text"
                          :value="option.id"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <template
                      v-else-if="
                        question.question_type === 'Checkbox for Multiple'
                      "
                    >
                      <v-checkbox-group v-model="selectedOptions[question.id]">
                        <v-checkbox
                          v-for="option in question.options"
                          v-model="selectedOptions[question.id]"
                          :key="option.id"
                          :label="option.option_text"
                          :value="option.id"
                        ></v-checkbox>
                      </v-checkbox-group>
                    </template>
                    <template v-else-if="question.question_type === 'Dropdown'">
                      <v-select
                        v-model="selectedOptions[question.id]"
                        :items="question.options"
                        item-text="option_text"
                        item-value="id"
                        label="Select an option"
                      ></v-select>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          style="background-color: rgb(216, 231, 215); width: 100%"
        >
          <v-btn
            color="primary"
            @click="previousQuestion"
            :disabled="activeQuestionIndex === 0"
          >
            Previous
          </v-btn>
          <v-btn
            color="primary"
            @click="nextQuestion"
            :disabled="
              activeQuestionIndex === selectedQuiz.questions.length - 1
            "
          >
            Next
          </v-btn>
          <v-btn
            color="primary"
            @click="submitQuiz"
            v-if="activeQuestionIndex === selectedQuiz.questions.length - 1"
          >
            Submit
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="red" style="float: right" @click="closePopup"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="showResults" style="margin-left: 20px; margin-left: 10px">
      <h3 style="margin-bottom: 40px; margin-top: 20px">Quiz Results</h3>
      <h2 style="margin-bottom: 40px; margin-top: 20px; font-size: 17px">
        Total Acheived :{{ totalMarks }}/{{ totalMarksOutOf }}
      </h2>

      <div
        v-for="(question, index) in selectedQuiz.questions"
        :key="index"
        style="margin-top: 10px"
      >
        <p style="font-weight: bold">
          <span v-if="isAnswerCorrect(question.id)" style="color: green"
            >&#10004;</span
          >
          <span v-else style="color: red">&#10006;</span>
          Question: {{ question.question_text }}
        </p>
        <ul>
          <li
            v-for="option in question.options"
            :key="option.id"
            :style="{ color: getOptionColor(question.id, option.id) }"
          >
            {{ option.option_text }}
          </li>
        </ul>
      </div>
      <p>Total Marks: {{ totalMarks }}</p>
    </div>
    <EditQuizDialog
      :selected-quiz="selectedQuiz"
      :show-edit-dialog="isEditMode"
      @save-quiz="saveQuiz"
      @cancel-edit="cancelEdit"
    ></EditQuizDialog>
  </div>
</template>


<script>
import EditQuizDialog from "./EditQuizDialog.vue";

export default {
  components: {
    EditQuizDialog,
  },

  data() {
    return {
      quizzesData: [],
      selectedQuiz: null,
      isEditMode: false,
      activeQuestionIndex: 0,
      selectedOptions: {},
      showPopup: false,
      totalMarks: 0,
      totalMarksOutOf: 0,
      showResults: false,
      correctAnswers: {},
      givenAnswers: {},
      timerMinutes: 0,
      timerSeconds: 0,
    };
  },
  mounted() {
    this.fetchQuizList();
  },
  methods: {
    previousQuestion() {
      if (this.activeQuestionIndex > 0) {
        this.activeQuestionIndex--;
      }
    },
    nextQuestion() {
      if (this.activeQuestionIndex < this.selectedQuiz.questions.length - 1) {
        this.activeQuestionIndex++;
      }
    },

    fetchQuizList() {
      this.$api.get("/quizzes/").then((response) => {
        this.quizzesData = response.data.results;
      });
    },
    showQuestions(quizId) {
      this.selectedQuiz = this.quizzesData.find((quiz) => quiz.id === quizId);
      this.selectedOptions = {};
      this.showPopup = true;
      this.startTimer();
    },
    startTimer() {
      setInterval(() => {
        this.timerSeconds++;
        if (this.timerSeconds === 60) {
          this.timerMinutes++;
          this.timerSeconds = 0;
        }
      }, 1000);
    },
    getOptionColor(questionId, optionId) {
      const question = this.selectedQuiz.questions.find(
        (question) => question.id === questionId
      );
      const correctOption = question.options.find(
        (option) => option.is_correct
      );
      const givenOption = question.options.find(
        (option) => option.id === optionId
      );
      const isCorrectAnswer = correctOption && correctOption.id === optionId;
      const isGivenAnswer =
        givenOption && givenOption.id === this.selectedOptions[questionId];

      if (isCorrectAnswer) {
        return "#9acc9b";
      }
      if (isGivenAnswer) {
        return "#dd878a";
      } else {
        return "black";
      }
    },

    isAnswerCorrect(questionId) {
      const correctAnswer = this.correctAnswers[questionId];
      const givenAnswer = this.givenAnswers[questionId];
      return correctAnswer === givenAnswer;
    },

    editQuiz(quizId) {
      this.selectedQuiz = this.quizzesData.find((quiz) => quiz.id === quizId);
      this.isEditMode = true;
    },

    submitQuiz() {
      this.totalMarks = 0;
      this.correctAnswers = {};
      this.givenAnswers = {};
      const answerPromises = this.selectedQuiz.questions.map((question) => {
        this.totalMarksOutOf += question.mark;

        const selectedOptionId = this.selectedOptions[question.id];
        const answer = {
          quiz: this.selectedQuiz.id,
          question: question.id,
          selected_option: selectedOptionId ? parseInt(selectedOptionId) : null,
          text_answer: null,
          file_answer: null,
          date: this.selectedQuiz.date,
          start_time: this.selectedQuiz.start_time,
          rooms: this.selectedQuiz.rooms,
          title: this.selectedQuiz.title,
          end_time: this.selectedQuiz.end_time,
        };

        if (question.question_type === "text") {
          answer.text_answer = this.selectedOptions[question.id];
        }

        if (question.question_type === "file") {
          const fileInput = this.$refs.fileInput;
          answer.file_answer = fileInput.files[0];
        }

        return this.$api
          .post("/quizzes/", answer)
          .then((response) => {
            const responseData = response.data;
            if (responseData.selected_option) {
              const selectedOption = question.options.find(
                (option) => option.id === responseData.selected_option
              );
              if (selectedOption && selectedOption.is_correct) {
                this.totalMarks += question.mark;
              }
              this.givenAnswers[question.id] = selectedOption
                ? selectedOption.option_text
                : "Not answered";
            } else if (responseData.text_answer) {
              this.givenAnswers[question.id] = responseData.text_answer;
            }

            const correctOption = question.options.find(
              (option) => option.is_correct
            );
            const correctAnswer = correctOption
              ? correctOption.option_text
              : "Not answered";
            this.correctAnswers[question.id] = correctAnswer;
          })
          .catch((error) => {
            console.error("Failed to submit answer:", error);
          });
      });

      Promise.all(answerPromises)
        .then(() => {
          this.closePopup();
          this.selectedOptions = {};
          this.showResults = true;
        })
        .catch((error) => {
          console.error("Failed to submit quiz:", error);
        });
    },
    saveQuiz(editedQuiz) {
      this.showEditDialog = false;
    },
    cancelEdit() {
      this.showEditDialog = false;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>
